import {BasicsClass} from "@/common/BasicsClass";
import {Util} from "@/common/util";

export default class buyAddressApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['orderPool', {
            url: '/order/pool',
            name: 'order-pool',
            label: '台桌列表'
        }],
        ['orderUsePools', {
            url: '/order/usePools',
            name: 'order-usePools',
            label: '使用中桌子'
        }],
        ['orderIndex', {
            url: '/order/index',
            name: 'order-index',
            label: '台桌预定订单'
        }],
        ['orderBill', {
            url: '/order/bill',
            name: 'order-bill',
            label: '结账详情'
        }],
        ['orderBind', {
            url: '/order/bind',
            name: 'order-bind',
            label: '并台订单'
        }],
        ['orderBindInfo', {
            url: '/order/bindInfo',
            name: 'order-bindInfo',
            label: '并台列表'
        }],
        ['orderBindCancel', {
            url: '/order/bindCancel',
            name: 'order-bindCancel',
            label: '并台撤销'
        }],
        ['orderCheck', {
            url: '/order/check',
            name: 'order-check',
            label: '订单预约时间校验'
        }],
        ['orderRenew', {
            url: '/order/renew',
            name: 'order-renew',
            label: '续费订单'
        }],
        ['orderBoot', {
            url: '/order/boot',
            name: 'order-boot',
            label: '开台'
        }],
        ['orderPay', {
            url: '/order/pay',
            name: 'order-pay',
            label: '预支付订单'
        }],
        ['orderRequest', {
            url: '/pay/request',
            name: 'pay-request',
            label: '支付请求'
        }],
        ['orderQuery', {
            url: '/pay/query',
            name: 'order-query',
            label: '查询支付订单'
        }],
        ['orderClosing', {
            url: '/order/closing',
            name: 'order-closing',
            label: '结账订单'
        }],
        ['orderChangePools', {
            url: '/order/changePools',
            name: 'order-changePools',
            label: '列表'
        }],
        ['orderChange', {
            url: '/order/change',
            name: 'order-change',
            label: '转台台桌列表'
        }],
        ['orderClassifyVerify', {
            url: '/order/classify/verify',
            name: 'order-classify-verify',
            label: '核销优惠券'
        }],
        ['orderCouponClassify', {
            url: '/order/coupon/classify',
            name: 'order-coupon-classify',
            label: '开台优惠券分类列表'
        }],
        ['orderCharge', {
            url: '/order/charge',
            name: 'order-charge',
            label: '订单支付费用查询'
        }],
        ['goodsScan', {
            url: '/goods/scan',
            name: 'goods-scan',
            label: '扫描条形码查询商品'
        }],
        ['goodsSaleCartDetail', {
            url: '/goodsSale/cart/detail',
            name: 'goodsSale-cart-detail',
            label: '商品购物车详情'
        }],
        ['goodsCategory', {
            url: '/goods/category',
            name: 'goodsCategory',
            label: '商品分类'
        }],
        ['goodsSaleCart', {
            url: '/goodsSale/cart',
            name: 'goodsSaleCart',
            label: '加入购物车'
        }],
        ['goodsSaleBuy', {
            url: '/goodsSale/buy',
            name: 'goodsSaleBuy',
            label: '购买商品'
        }],
        ['goodsQuery', {
            url: '/goods/query',
            name: 'goodsQuery',
            label: '手动查询销售商品'
        }],
        ['shopLists', {
            url: '/shop/lists',
            name: 'shop-lists',
            label: '店铺列表'
        }],
        ['memberSearch', {
            url: '/member/search',
            name: 'member-search',
            label: '会员搜索'
        }],
        ['memberInfo', {
            url: '/member/info',
            name: 'member-info',
            label: '查询会员信息'
        }],
        ['assistantShow', {
            url: '/assistant/show',
            name: 'assistant-show',
            label: '助教下拉列表'
        }],
        ['assistantOrder', {
            url: '/assistant/order',
            name: 'assistant-order',
            label: '助教下单'
        }],
        ['assistantOver', {
            url: '/assistant/over',
            name: 'assistant-over',
            label: '助教结算'
        }],
        ['orderGoodsSale', {
            url: '/order/goodsSale',
            name: 'order-goods-sale',
            label: '桌子待配送商品数量'
        }],
        ['goodsSaleDeliveryList', {
            url: '/goodsSale/deliveryList',
            name: 'goodsSaleDeliveryList',
            label: '商品待配送详情'
        }],
        ['goodsSaleDelivery', {
            url: '/goodsSale/delivery',
            name: 'goodsSaleDelivery',
            label: '配送'
        }],
        ['goodsSaleAllDelivery', {
            url: '/goodsSale/allDelivery',
            name: 'goodsSaleAllDelivery',
            label: '全部配送'
        }],
        ['goodsSaleDeleteDelivery', {
            url: '/goodsSale/deleteDeliveryOrder',
            name: 'goodsSaleDeleteDelivery',
            label: '删除商品点单'
        }],
        ['ossSts', {
            url: '/oss/sts',
            name: 'ossSts',
            label: '上传'
        }],
    ])

    //台桌列表-----1
    public pool(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderPool').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //使用中桌子----2
    public usePools(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderUsePools').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //台桌预定订单---4
    public orderindex(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderIndex').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //结账详情---5
    public orderBill(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderBill').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //并台订单---6
    public mergePoolOrder(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderBind').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //并台列表---7
    public mergePoolList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderBindInfo').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //并台撤销---8
    public cancelMergeOrder(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderBindCancel').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---9
    public ordercheck(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderCheck').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //续费订单  ---10
    public orderRenew(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderRenew').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---11
    public orderboot(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderBoot').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---12
    public orderpay(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderPay').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---13
    public payrequest(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderRequest').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---14
    public payquery(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderQuery').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---15
    public goodscan(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsScan').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }


    public overorder(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderClosing').url
        this.BasicPost(
            // "/order/over",
            url,
            {
                ...params,
            },
            false,
            false,
            true,
            successCb,
            failCb,
            showLoading
        );
    }

    //---18
    public shopList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('shopLists').url
        this.BasicPost(
            url,
            {
                ...params,
                type: this.$store.state.platformType
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---19
    //手机号查询
    public searchMobile(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('memberSearch').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---20
    public searchmember(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('memberInfo').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public assistantshow(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('assistantShow').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public assistantorder(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('assistantOrder').url
        const order_time = Util.dateTimeUtil(params.order_time)
        this.BasicPost(
            url,
            {
                ...params,
                order_time: order_time
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---23
    public changePools(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderChangePools').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---24
    public orderchange(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderChange').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---25
    public cartdetail(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsSaleCartDetail').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public goodscategory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsCategory').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public goodsSalecart(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsSaleCart').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public goodsSale(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsSaleBuy').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public goodsquery(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsQuery').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---26
    public assistantover(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('assistantOver').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    // 核销优惠券 ------27
    public verifyCard(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderClassifyVerify').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //---28
    public classifys(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderCouponClassify').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public charge(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderCharge').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public orderGoodsSale(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderGoodsSale').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public goodsSaleDeliveryList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsSaleDeliveryList').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public goodsSaleDelivery(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsSaleDelivery').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public goodsSaleAllDelivery(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsSaleAllDelivery').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    public goodsSaleDeleteDelivery(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsSaleDeleteDelivery').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    public ossSts(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('ossSts').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }


}
